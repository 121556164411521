const frequency: [number, string][] = [
  [27.5, 'A0'],
  [29.135, 'Bb0'],
  [30.868, 'B0'],
  [32.703, 'C1'],
  [34.648, 'Db1'],
  [36.708, 'D1'],
  [38.891, 'Eb1'],
  [41.203, 'E1'],
  [43.654, 'F1'],
  [46.249, 'Gb1'],
  [48.999, 'G1'],
  [51.913, 'Ab1'],
  [55, 'A1'],
  [58.27, 'Bb1'],
  [61.735, 'B1'],
  [65.406, 'C2'],
  [69.296, 'Db2'],
  [73.416, 'D2'],
  [77.782, 'Eb2'],
  [82.407, 'E2'],
  [87.307, 'F2'],
  [92.499, 'Gb2'],
  [97.999, 'G2'],
  [103.826, 'Ab2'],
  [110, 'A2'],
  [116.541, 'Bb2'],
  [123.471, 'B2'],
  [130.813, 'C3'],
  [138.591, 'Db3'],
  [146.832, 'D3'],
  [155.563, 'Eb3'],
  [164.814, 'E3'],
  [174.614, 'F3'],
  [184.997, 'Gb3'],
  [195.998, 'G3'],
  [207.652, 'Ab3'],
  [220, 'A3'],
  [233.082, 'Bb3'],
  [246.942, 'B3'],
  [261.626, 'C4'],
  [277.183, 'Db4'],
  [293.665, 'D4'],
  [311.127, 'Eb4'],
  [329.628, 'E4'],
  [349.228, 'F4'],
  [369.994, 'Gb4'],
  [391.995, 'G4'],
  [415.305, 'Ab4'],
  [440, 'A4'],
  [466.164, 'Bb4'],
  [493.883, 'B4'],
  [523.251, 'C5'],
  [554.365, 'Db5'],
  [587.33, 'D5'],
  [622.254, 'Eb5'],
  [659.255, 'E5'],
  [698.456, 'F5'],
  [739.989, 'Gb5'],
  [783.991, 'G5'],
  [830.609, 'Ab5'],
  [880, 'A5'],
  [932.328, 'Bb5'],
  [987.767, 'B5'],
  [1046.502, 'C6'],
  [1108.731, 'Db6'],
  [1174.659, 'D6'],
  [1244.508, 'Eb6'],
  [1318.51, 'E6'],
  [1396.913, 'F6'],
  [1479.978, 'Gb6'],
  [1567.982, 'G6'],
  [1661.219, 'Ab6'],
  [1760, 'A6'],
  [1864.655, 'Bb6'],
  [1975.533, 'B6'],
  [2093.005, 'C7'],
  [2217.461, 'Db7'],
  [2349.318, 'D7'],
  [2489.016, 'Eb7'],
  [2637.02, 'E7'],
  [2793.826, 'F7'],
  [2959.955, 'Gb7'],
  [3135.963, 'G7'],
  [3322.438, 'Ab7'],
  [3520, 'A7'],
  [3729.31, 'Bb7'],
  [3951.066, 'B7'],
  [4186.009, 'C8'],
]

export default frequency
