import React from 'react'
import LFO from './lfo'

const ControlPanel = () => {
  return (
    <div>
      <LFO></LFO>
    </div>
  )
}

export default ControlPanel
